import {useState} from 'react'
import {Form, Button, Row, Col, Alert, Container, Spinner} from 'react-bootstrap'
import { axiosInstance } from './utilities/axios';
import styled from "styled-components";

const LinkButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-size: 0.9em;
  display: inline;
  margin-bottom: .75em;
  padding: 0;
`

function UnauthenticatedApp({onSignIn}){
  const [validated, setValidated] = useState(false)
  const [signInView, setSignInView] = useState("login")
  const [signUpSignInRes, setSignUpSignInRes] = useState("");

  const handleSignIn = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if(form.checkValidity() === false){
      event.stopPropagation();
    }else{
      let userData = {
        email: event.target.formBasicEmail.value,
        password: event.target.formBasicPassword.value
      }
      setSignInView("waitingForResponse");
      requestAccessToken(userData).then(result => {
        if(result.loginSuccess){
          setSignInView("signInSignUpResponse")
          setSignUpSignInRes(result.message)
          onSignIn(result.user);
        }else{
          setSignInView("signInSignUpResponse")
          setSignUpSignInRes(result.message)
        }
      });

    }
    setValidated(true);
  }

  const handleSignUp = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if(form.checkValidity() === false){
      event.stopPropagation();
    }else{
      //Form was validated continue sending user info to sign up
      let userData = {
        email: event.target.formBasicEmail.value,
        firstName: event.target.formFirstName.value,
        lastName: event.target.formLastName.value,
        password: event.target.formBasicPassword.value
      }
      //Change view to loading screen
      setSignInView("waitingForResponse")
      signUp(userData).then(res => {
        setSignInView("signInSignUpResponse")
        setSignUpSignInRes(res)
      })
    }
    setValidated(true);
  }

  const handleSignUpView = (event) => {
    switch (event.target.id){
      case "formSignUpLink":
        setValidated(false);
        setSignInView("signup");
      break;
      case "formSignUpCancelLink":
        setSignInView("login")
        setValidated(false);
      break;
      default:
        setSignInView("login")
        setValidated(false);
    }
  }

  const LoginControl = ({validated, signInView, signUpRes: signUpSignInRes}) => {
    let control;
    switch (signInView){
    case "waitingForResponse":
      control = 
        <Spinner animation="border" role="status" variant="dark">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      break;
    case "signInSignUpResponse":
      control =
        <Container>
          <Row className="justify-content-center">
            <Col xs="auto">
            <Alert variant="light">
              {signUpSignInRes}
            </Alert>
            </Col>
          </Row>  
          <Row className="justify-content-center">
            <LinkButton id="formBackToLogInLink" type="button" className="link-button" onClick={handleSignUpView}>
              Back to Log In
            </LinkButton>
          </Row>
        </Container>
    break;
    case "signup":
      control = 
      <Form noValidate validated={validated} onSubmit={handleSignUp}>
        <Row className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" required/>
            <Form.Control.Feedback type="invalid">
              Please provide an email.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formFirstName">
            <Form.Label>First name</Form.Label>
            <Form.Control type="text" placeholder="Enter first name" required/>
            <Form.Control.Feedback type="invalid">
              Please provide a first name.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formLastName">
            <Form.Label>Last name</Form.Label>
            <Form.Control type="text" placeholder="Enter last name" required/>
            <Form.Control.Feedback type="invalid">
              Please provide a last name.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Create your own password" required/>
            <Form.Control.Feedback type="invalid">
              Please provide a password.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <LinkButton id="formSignUpCancelLink" type="button" className="link-button" onClick={handleSignUpView}>
            Cancel
          </LinkButton>
        </Row>
        <Button variant="primary" disabled={false} type="submit" >
          Sign Up
        </Button>
      </Form>
      break;
    default:
      //Login is the default view
      control = 
      <Form noValidate validated={validated} onSubmit={handleSignIn}>
        <Row  className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Enter email" required/>
            <Form.Control.Feedback type="invalid">
              Please provide an email.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <Form.Group as={Col} xs="auto" className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" required/>
            <Form.Control.Feedback type="invalid">
              Please provide a password.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row  className="justify-content-center">
          <LinkButton id="formSignUpLink" type="button" className="link-button" onClick={handleSignUpView}>
            Sign Up
          </LinkButton>
        </Row>
        <Button variant="primary" disabled={false} type="submit" >
          Login
        </Button>
      </Form>
    }
    return control;
  }

  return(
    <div className="login-form">
      <div className="login-logo">Vroom Photos</div>
      <LoginControl validated={validated} signInView={signInView} signUpRes={signUpSignInRes}/>
    </div>
  )
}

async function requestAccessToken(userData){
  let data = {
    message: "login"
  }
  const response = await axiosInstance.post('/api/auth/login', data, {
    auth: {
      username: userData.email,
      password: userData.password
    },
    withCredentials: true
  })
  return response.data;
}

async function signUp(userData){
  let data = {
    firstName: userData.firstName,
    lastName: userData.lastName
  }
  const response = await axiosInstance.post('/api/auth/signup', data, {
    auth: {
      username: userData.email,
      password: userData.password
    }
  })
  return response.data.message;
}

export default UnauthenticatedApp;
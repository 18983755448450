import NavHeader from "./components/NavHeader";
import UploadManager from "./components/UploadManager";
import NavFooter from "./components/NavFooter";
import Admin from "./components/Admin";
import {useEffect, useState} from 'react'
import GridViewer from "./components/GridViewer";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useSwipeable } from "react-swipeable";
import { idb } from "./utilities/idb";
import {Spinner, Image} from 'react-bootstrap';
import 'swiper/swiper.scss'
import xlg from './icons/xlg.svg'

import SwiperCore, {Lazy} from 'swiper';
  
// install Swiper modules
SwiperCore.use([Lazy]);

function AuthenticatedApp({user, onSignOut}){
    const [viewKey, setViewKey] = useState('upload');
    const [lastViewKey, setLastViewKey] = useState('upload');
    
    //UploadManager state variables
    const [jobNumberInput, setJobNumberInput] = useState('');
    const [jobSearchResult, setJobSearchResult] = useState('');
    const [foundJobFolder, setFoundJobFolder] = useState(false);
    const [uploadList, setUploadList] = useState([]); 

    //PhotoViewer state variables
    const [jobNumberInput_pv, setJobNumberInput_pv] = useState('');
    const [jobSearchResult_pv, setJobSearchResult_pv] = useState('');
    const [foundJobFolder_pv, setFoundJobFolder_pv] = useState(false);
    const [loadingPhotos, setLoadingPhotos] = useState(false);
    const [loadedPhotos, setLoadedPhotos] = useState([]);
    const [photoSearchDone, setPhotoSearchDone] = useState(false);
    const [showPhotoView, setShowPhotoView] = useState(false);
    const [initialPhotoIndex, setInitialPhotoIndex] = useState(0)

    //Load saved states from IndexedDB on page load
    useEffect(() =>{
        getStateByNameFromIndexedDB('viewKey').then(savedState => {if(savedState !== undefined) setViewKey(savedState.state)})
        getStateByNameFromIndexedDB('lastViewKey').then(savedState => {if(savedState !== undefined)setLastViewKey(savedState.state)})
        getStateByNameFromIndexedDB('jobNumberInput').then(savedState => {if(savedState !== undefined)setJobNumberInput(savedState.state)})
        getStateByNameFromIndexedDB('jobSearchResult').then(savedState => {if(savedState !== undefined)setJobSearchResult(savedState.state)})
        getStateByNameFromIndexedDB('foundJobFolder').then(savedState => {if(savedState !== undefined)setFoundJobFolder(savedState.state)})
        getStateByNameFromIndexedDB('uploadList').then(savedState => {if(savedState !== undefined)setUploadList(savedState.state)})
        getStateByNameFromIndexedDB('jobNumberInput_pv').then(savedState => {if(savedState !== undefined)setJobNumberInput_pv(savedState.state)})
        getStateByNameFromIndexedDB('jobSearchResult_pv').then(savedState => {if(savedState !== undefined)setJobSearchResult_pv(savedState.state)})
        getStateByNameFromIndexedDB('foundJobFolder_pv').then(savedState => {if(savedState !== undefined)setFoundJobFolder_pv(savedState.state)})
        getStateByNameFromIndexedDB('loadingPhotos').then(savedState => {if(savedState !== undefined)setLoadingPhotos(savedState.state)})
        getStateByNameFromIndexedDB('loadedPhotos').then(savedState => {if(savedState !== undefined)setLoadedPhotos(savedState.state)})
        getStateByNameFromIndexedDB('photoSearchDone').then(savedState => {if(savedState !== undefined)setPhotoSearchDone(savedState.state)})
        getStateByNameFromIndexedDB('showPhotoView').then(savedState => {if(savedState !== undefined)setShowPhotoView(savedState.state)})
        getStateByNameFromIndexedDB('initialPhotoIndex').then(savedState => {if(savedState !== undefined)setInitialPhotoIndex(savedState.state)})
    },[])

    //Save states to IndexDB when they change
    useEffect(()=>{saveAppStateToIndexedDB({name: 'viewKey', state: viewKey})}, [viewKey])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'lastViewKey', state: lastViewKey})}, [lastViewKey])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'jobNumberInput', state: jobNumberInput})}, [jobNumberInput])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'jobSearchResult', state: jobSearchResult})}, [jobSearchResult])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'foundJobFolder', state: foundJobFolder})}, [foundJobFolder])
    // useEffect(()=>{
    //     //IndexedDB throws an error when saving objects with a promise, just remove the promise
    //     //since it is impossible to reinstate the promise when reloading the object from the db.
    //     const uploadListWithoutPromises = uploadList.map((uploadItem) => {
    //         let newUploadItem = uploadItem;
    //         newUploadItem.cancelSource = null;
    //         return newUploadItem;
    //     })
    //     saveAppStateToIndexedDB({name: 'uploadList', state: uploadListWithoutPromises})
    // }, [uploadList])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'jobNumberInput_pv', state: jobNumberInput_pv})}, [jobNumberInput_pv])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'jobSearchResult_pv', state: jobSearchResult_pv})}, [jobSearchResult_pv])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'foundJobFolder_pv', state: foundJobFolder_pv})}, [foundJobFolder_pv])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'loadingPhotos', state: loadingPhotos})}, [loadingPhotos])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'loadedPhotos', state: loadedPhotos})}, [loadedPhotos])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'photoSearchDone', state: photoSearchDone})}, [photoSearchDone])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'showPhotoView', state: showPhotoView})}, [showPhotoView])
    useEffect(()=>{saveAppStateToIndexedDB({name: 'initialPhotoIndex', state: initialPhotoIndex})}, [initialPhotoIndex])


    var swiperInstance = null;

    const handleNewSwiper = (swiper) => {
        swiperInstance = swiper;
    }

    const handleKeySelect = (viewKeySelected) => {
        if(viewKeySelected === 'lastview'){
            let presentViewKey = viewKey;
            setViewKey(lastViewKey)
            setLastViewKey(presentViewKey)
            return;
        }
        if(viewKeySelected !== viewKey){
            setLastViewKey(viewKey)
            setViewKey(viewKeySelected)
        }
    }

    const handleSelectGridItem = (event) => {
        const index = event.target.name;
        setInitialPhotoIndex(index);
        setShowPhotoView(true);
        handleKeySelect('fullscreen')
    }

    const handleSwiperClose = () =>{
        handleKeySelect('view')
        setShowPhotoView(false);
        if(swiperInstance !== null){
            swiperInstance.destroy(true,true)
        }
    }

    const reactSwipeableHandlers = useSwipeable({
        onSwipedDown: (eventData) => {
            handleSwiperClose();
        }
    })

    const PhotoView = ({photos, startIndex, showPhotoView}) => {
        let photoview;
        if(!showPhotoView){
            photoview=
            <div className="photo-view-hidden"></div>
            return photoview
        }

        photoview=
            <Swiper 
                spaceBetween={10}
                slidesPerView={1}
                onSwiper={(swiper)=>{handleNewSwiper(swiper)}}
                initialSlide={startIndex}
                className="swiper-h"
                autoHeight={true}
                lazy={{loadPrevNext: true}}
            >
                {photos.map((photo, index) => {
                    let src = "/api/images?filename=" + photo.filename;
                    let slide =
                        <SwiperSlide key={index} >
                            <div className="swipe-down">
                                {/* <img className="swiper-image" id={photo.filename} src={src} alt="grid-img"/>*/}
                                <img id={photo.filename} data-src={src} alt="" className="swiper-lazy swiper-image" />
                                <div className="swiper-lazy-preloader">
                                    <Spinner animation="border" role="status" variant="dark">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </div>
                                <div>
                                <Image className="swiper-close" src={xlg} onClick={() => {handleSwiperClose();}}/>
                                </div>
                            </div>
                        </SwiperSlide>
                    return slide;
                })}
            </Swiper>
        return photoview;
    }

    var view;
    switch(viewKey){
        case "upload":
        view = 
        <div>
        <NavHeader user={user} onSignOut={onSignOut} onSelectMenu={handleKeySelect}/>
        <UploadManager 
            user={user} 
            jobNumberInput={jobNumberInput} 
            setJobNumberInput={setJobNumberInput}
            jobSearchResult={jobSearchResult}
            setJobSearchResult={setJobSearchResult}
            foundJobFolder={foundJobFolder}
            setFoundJobFolder={setFoundJobFolder}
            uploadList={uploadList}
            setUploadList={setUploadList}
        />
        <NavFooter user={user} activeKey={viewKey} onKeySelect={handleKeySelect}/>
        </div>
        break;
        case "admin":
            view = 
            <div>
            <NavHeader user={user} onSignOut={onSignOut} onSelectMenu={handleKeySelect}/>
            <Admin onCloseAdmin={handleKeySelect}/>
            </div>
            break;
        case 'fullscreen':
            view=
            <div {...reactSwipeableHandlers} className="photo-view">
                <PhotoView  photos={loadedPhotos} startIndex={initialPhotoIndex} showPhotoView={showPhotoView} />
            </div>
        break;
        default:
            view =
            <div>
            <NavHeader user={user} onSignOut={onSignOut} onSelectMenu={handleKeySelect}/>
            <GridViewer 
                user={user}
                jobNumberInput={jobNumberInput_pv} 
                setJobNumberInput={setJobNumberInput_pv}
                jobSearchResult={jobSearchResult_pv}
                setJobSearchResult={setJobSearchResult_pv}
                foundJobFolder={foundJobFolder_pv}
                setFoundJobFolder={setFoundJobFolder_pv}
                loadingPhotos={loadingPhotos}
                setLoadingPhotos={setLoadingPhotos}
                loadedPhotos={loadedPhotos}
                setLoadedPhotos={setLoadedPhotos}
                photoSearchDone={photoSearchDone}
                setPhotoSearchDone={setPhotoSearchDone}
                showPhotoView={showPhotoView}
                setShowPhotoView={setShowPhotoView}
                initialPhotoIndex={initialPhotoIndex}
                setInitialPhotoIndex={setInitialPhotoIndex}
                handleSelectGridItem={handleSelectGridItem}
            />
            <NavFooter user={user} activeKey={viewKey} onKeySelect={handleKeySelect}/>
        </div>
    }

    return(
         <div>
             
             {view}
            
         </div>        
    )
}

async function saveAppStateToIndexedDB(state) {
    if(state === null || state === undefined){
        return 'No states to save.';
    }

    const vroomphotosDB = await idb.vroomphotosDB
    vroomphotosDB.put('states', {name: state.name, state: state.state})
}

async function getStateByNameFromIndexedDB(name) {
    const vroomphotosDB = await idb.vroomphotosDB
    const state = await vroomphotosDB.get('states',name)

    return state;
}

export default AuthenticatedApp;
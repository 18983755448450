import React, {useEffect} from 'react';
import JobSearch from './JobSearch';
import InputFile from './InputFile';
//import Thumbnail from './Thumbnail.js';
import {axiosInstance} from "../utilities/axios";
import axios from 'axios';
import {Image as ImageBS, Container,Row,Col, ProgressBar, Button} from 'react-bootstrap'
//import check from '../icons/check.svg'
//import checkLg from '../icons/check-lg.svg'
import cloudCheck from '../icons/cloud-check.svg'
import cancelRemove from '../icons/cancel-remove.svg'
import arrowClockwise from '../icons/arrow-clockwise.svg'
import exclamationTriangle from '../icons/exclamation-triangle.svg'

function UploadManager({
  user, 
  jobNumberInput, setJobNumberInput, 
  jobSearchResult, setJobSearchResult,
  foundJobFolder, setFoundJobFolder,
  uploadList, setUploadList}) { 
  //const [jobNumberInput, setJobNumberInput] = useState('');
  //const [jobSearchResult, setJobSearchResult] = useState('');
  //const [foundJobFolder, setFoundJobFolder] = useState(false);
  //const [uploadList, setUploadList] = useState([]); 

  const handleNewFiles = async (newFiles) => {
    if(newFiles.length === 0){
      //no files to add
    }else{
      for(const file of newFiles){
          let uploadItem = {
            file: file,
            name: file.name,
            fileType: file.type.split('/')[0],
            fileSubType: file.type.split('/')[1],
            id: Date.now() + '-' + Math.round(Math.random() * 1E9),
            fileURL: URL.createObjectURL(file),//await readFile(file),
            uploadStatus: "Waiting to upload",
            uploadError: "",
            uploadProgress: 0,
            cancelSource: null
          }
          setUploadList(uploadList => [...uploadList, uploadItem])
      }
    }
  }

  const removeItem = (id) => {
    const newUploadList = uploadList.filter((item) => (item.id !== id))

    setUploadList(newUploadList)
  }

  const updateItem = (id, property, value) => {
    const newUploadList = uploadList.map((uploadItem) => {
      if(uploadItem.id === id){
        let newUploadItem = uploadItem;
        newUploadItem[property] = value;
        return newUploadItem;
      }
      return uploadItem;
    })
    setUploadList(newUploadList)
  }

  const numOfAllowedUploads = 2;
  var numOfActiveUploads = getFileListUploadStatus().numActive;
  var numOfFilesWaitingForUpload = getFileListUploadStatus().numWaiting;

  useEffect(() => {
    if(numOfActiveUploads < numOfAllowedUploads && numOfFilesWaitingForUpload > 0){
      uploadSearch()
    }
  })


  function getFileListUploadStatus() {
    let numActive = 0;
    let numWaiting = 0;
    for(const uploadItem of uploadList){
      if(uploadItem.uploadStatus === "Uploading"){
        numActive = numActive + 1;
      }
      if(uploadItem.uploadStatus === "Waiting to upload"){
        numWaiting = numWaiting + 1;
      }
    }
    return {
      numActive: numActive, 
      numWaiting: numWaiting
    };
  }

  function uploadSearch() {
    const newUploadList = uploadList.map((uploadItem) => {
      if(uploadItem.uploadStatus === "Waiting to upload"){
        if(numOfActiveUploads < numOfAllowedUploads){
          let newUploadItem = uploadSingle(uploadItem);
          return newUploadItem;
        }
      }
      return uploadItem;
    })
    setUploadList(newUploadList);
  }

  function uploadSingle(uploadItem){
    let newUploadItem = uploadItem;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    newUploadItem.cancelSource = source;

    const config = {
      headers: {
                "Content-Type": "multipart/form-data",
              },
      cancelToken: source.token,
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateItem(uploadItem.id,'uploadProgress',percentCompleted);
      }
    }

    switch(newUploadItem.fileType){
      case "video":
        numOfFilesWaitingForUpload=numOfFilesWaitingForUpload-1;
        newUploadItem.uploadError = "Videos with file type " + newUploadItem.fileSubType + " are not handled yet.";
        newUploadItem.uploadStatus = "Error"
        return newUploadItem;
      case "image":
        let data = new FormData()
        data.set('width', uploadItem.imageWidth)
        data.set('height', uploadItem.imageHeight)
        data.set('user', JSON.stringify(user))
        data.set('jobnumber', jobNumberInput)
        data.append('image', uploadItem.file, uploadItem.id)
    
        axiosInstance.post('/api/images', data, config)
          .then(res => {
            if(res.data.message === 'Upload Success'){
              updateItem(uploadItem.id, 'uploadStatus', "Uploaded")
              numOfActiveUploads=numOfActiveUploads-1;
            }else{
              updateItem(uploadItem.id, 'uploadStatus', res.data.message)
            }
          })
          .catch(error => {
            if(axios.isCancel(error)){
              updateItem(uploadItem.id,'uploadStatus',"Cancelled")
            }else{
              updateItem(uploadItem.id,'uploadStatus',"Error")
              updateItem(uploadItem.id,'uploadError',error.message)
            }
          })

          numOfActiveUploads=numOfActiveUploads+1;
          numOfFilesWaitingForUpload=numOfFilesWaitingForUpload-1;
          newUploadItem.uploadStatus = "Uploading"
          return newUploadItem;
      default:
        numOfFilesWaitingForUpload=numOfFilesWaitingForUpload-1;
        newUploadItem.uploadStatus = "Error"
        newUploadItem.uploadError = "Cannot use file type " + newUploadItem.fileType;
        return newUploadItem;
    }
  }

  const UploadControl = ({uploadItem}) => {
    let control;
    switch(uploadItem.uploadStatus){
      case "Waiting to upload":
        control = <Button variant="outline-primary" size="sm" onClick={() => {
          removeItem(uploadItem.id);
          }}> Remove </Button>
          break;
      case "Uploading":
        control = 
        // <Button variant="outline-primary" size="sm" onClick={() => {uploadItem.cancelSource.cancel();}}> Cancel </Button>
        <ImageBS className="upload-control" src={cancelRemove} onClick={() => {uploadItem.cancelSource.cancel();}}/>
          break;
      case "Cancelled":
        control = 
          <div>
            {/* <Button variant="outline-primary" size="sm" onClick={() => {uploadSingle(uploadItem);}}>Retry</Button>{' '}
            <Button variant="outline-primary" size="sm" onClick={() => {removeItem(uploadItem.id);}}>Remove</Button> */}
            <ImageBS className="upload-control" src={cancelRemove} onClick={() => {removeItem(uploadItem.id);}}/> {' '}
            <ImageBS className="upload-control" src={arrowClockwise} onClick={() => {uploadSingle(uploadItem);}}/>
          </div>
          break;
      case "Uploaded":
        control = 
        // <Button variant="outline-primary" size="sm" onClick={() => {removeItem(uploadItem.id);}}> Remove </Button>
        <ImageBS className="upload-control" src={cancelRemove} onClick={() => {removeItem(uploadItem.id);}}/>
          break;
      case "Error":
        control = 
        // <Button variant="outline-primary" size="sm" onClick={() => {uploadSingle(uploadItem);}}> Retry </Button>
        <ImageBS className="upload-control" src={arrowClockwise} onClick={() => {uploadSingle(uploadItem);}}/>
          break;
      default:
        control = <div></div>
    }
    return control;
  }
  
  const Upload = ({uploadItem}) => {
    let container;
    switch(uploadItem.uploadStatus){
      case "Uploading":
        container=
    <Container>
      <Row className="align-items-center">
        <Col xs="3" md="2">
          <ImageBS src={uploadItem.fileURL} thumbnail/>
        </Col>
        <Col>
          <span className="small-text">{uploadItem.uploadStatus}</span>
        </Col>
        <Col>
          <ProgressBar now={uploadItem.uploadProgress} />
        </Col>
        <Col xs="auto">
          <UploadControl uploadItem={uploadItem}/>
        </Col>
      </Row>
    </Container>
      break;
    case "Cancelled":
      container=
      <Container>
        <Row className="align-items-center">
          <Col xs="3" md="2">
            <ImageBS src={uploadItem.fileURL} thumbnail />
          </Col>
          <Col xs="auto">
          <ImageBS src={exclamationTriangle} width="16" height="16" /><span className="small-text"> {uploadItem.uploadStatus}</span>
          </Col>
          <Col >
            <div></div>
          </Col>
          <Col xs="auto">
            <UploadControl uploadItem={uploadItem}/>
          </Col>
        </Row>
      </Container>
      break;
    case "Uploaded":
      container=
      <Container>
        <Row className="align-items-center">
          <Col xs="3" md="2">
          <ImageBS src={uploadItem.fileURL} thumbnail/>
          </Col>
          <Col xs="auto">
            <ImageBS src={cloudCheck} /><span className="small-text"> Uploaded</span>
          </Col>
          <Col >
            <div></div>
          </Col>
          <Col xs="auto">
            <UploadControl uploadItem={uploadItem}/> 
          </Col>
        </Row>
      </Container> 
      break;
    case "Waiting to upload":
        container=
        <Container>
          <Row className="align-items-center">
            <Col xs="3" md="2">
            <ImageBS src={uploadItem.fileURL} thumbnail/>
            </Col>
            <Col>
              <div></div>
            </Col>
            <Col>
              <span className="small-text">{uploadItem.uploadStatus}</span>
            </Col>
            <Col xs="auto">
              <UploadControl uploadItem={uploadItem}/>
            </Col>
          </Row>
        </Container> 
        break;
        case "Error":
          container=
          <Container>
            <Row className="align-items-center">
              <Col xs="3" md="2">
                <ImageBS src={uploadItem.fileURL} thumbnail />
              </Col>
              <Col>
                {uploadItem.uploadError}
              </Col>
              <Col>
                <div></div>
              </Col>
              <Col Col xs="auto">
                <UploadControl uploadItem={uploadItem}/>
              </Col>
            </Row>
          </Container>
          break;
    default:
      container=
      <Container>
        <Row className="align-items-center">
          <Col>
          <ImageBS src={uploadItem.fileURL} thumbnail/>
          </Col>
          <Col>
            <div></div>
          </Col>
          <Col>
            <div></div>
          </Col>
          <Col>
          <span className="small-text"> Error: {uploadItem.uploadStatus}</span>
          </Col>
        </Row>
      </Container> 
  }
  return container;
}
  

  return(
    <Container>
      <Container className="sticky">
        <JobSearch user={user} jobNumberInput={jobNumberInput} onChange={setJobNumberInput} onJobSearchResult={setJobSearchResult} onJobFound={setFoundJobFolder}/>
        <label>
          {jobSearchResult}
        </label>

        <InputFile onFileInput={handleNewFiles} disabled={!foundJobFolder}/>
        <hr />
      </Container>
      <ul className="upload-list">{uploadList.map((uploadItem) => (
        <Upload uploadItem={uploadItem} key={uploadItem.id}/>
      ))}</ul>

    </Container>
    )   
}

export default UploadManager;
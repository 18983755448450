import React, {useState} from 'react';
import {Button, ButtonGroup, ToggleButton, Table} from 'react-bootstrap'
import { axiosInstance } from '../utilities/axios';
  
function Admin({user, onCloseAdmin}){
    const [userList, setUserList] = useState([]);

    const handleClose = (event) => {
        onCloseAdmin('lastview')
    }

    const handleUsersPendingApproval = (event) => {
        getUsersPendingApproval().then(users => 
            setUserList(users)
        )
    }

    const handleUsersAll = (event) => {
        getAllUsers().then(users => {
            setUserList(users)
        })
    }

    const handleApproveUser = (user) => {
        updateUser(user.username,'status','Updating...')
        approveUser(user).then(user => {
            updateUser(user.username,'status',user.status)
        })
    }

    const updateUser = (username, property, value) => {
        const newUserList = userList.map((user) => {
          if(user.username === username){
            let newUser = user;
            newUser[property] = value;
            return newUser;
          }
          return user;
        })
        setUserList(newUserList)
    }

    // const User_old = ({user}) => {
    //     let container;
    //     switch(user.status){
    //       case "pendingApproval":
    //         container=
    //             <Container>
    //             <Row className="align-items-center">
    //                 <Col>
    //                     {user.firstname + " " + user.lastname}
    //                 </Col>
    //                 <Col>
    //                     {user.username}
    //                 </Col>
    //                 <Col>
    //                     Pending
    //                 </Col>
    //                 <Col>
    //                     <Button onClick={() => {handleApproveUser(user)}} size="sm">Approve</Button>
    //                 </Col>
    //             </Row>
    //             </Container>
    //       break;
    //       case "Updating...":
    //         container=
    //         <Container>
    //         <Row className="align-items-center">
    //             <Col>
    //                 {user.firstname + " " + user.lastname}
    //             </Col>
    //             <Col>
    //                 {user.username}
    //             </Col>
    //             <Col>
    //                 Pending
    //             </Col>
    //             <Col>
    //                 <Button size="sm">Updating...</Button>
    //             </Col>
    //         </Row>
    //         </Container>
    //       break;
    //       case "approved":
    //         container=
    //         <Container>
    //         <Row className="align-items-center">
    //             <Col>
    //                 {user.firstname + " " + user.lastname}
    //             </Col>
    //             <Col>
    //                 {user.username}
    //             </Col>
    //             <Col>
    //                 Approved
    //             </Col>
    //             <Col>
    //                 <div></div>
    //             </Col>
    //         </Row>
    //         </Container>
    //       break;
    //       default:
    //         container=
    //         <div></div>
    //     }
    //     return container
    // }

    const User = ({user}) => {
        let container;
        switch(user.status){
          case "pendingApproval":
            container=
                <tr>
                    <td>
                        {user.firstname + " " + user.lastname}
                    </td>
                    <td>
                        {user.username}
                    </td>
                    <td>
                        Pending
                    </td>
                    <td>
                        <Button onClick={() => {handleApproveUser(user)}} size="sm">Approve</Button>
                    </td>
                </tr>
          break;
          case "Updating...":
            container=
            <tr>
                <td>
                    {user.firstname + " " + user.lastname}
                </td>
                <td>
                    {user.username}
                </td>
                <td>
                    Pending
                </td>
                <td>
                    <Button size="sm">Updating...</Button>
                </td>
            </tr>
          break;
          case "approved":
            container=
            <tr>
                <td>
                    {user.firstname + " " + user.lastname}
                </td>
                <td>
                    {user.username}
                </td>
                <td>
                    Approved
                </td>
                <td>
                    <div></div>
                </td>
            </tr>

          break;
          default:
            container=
            <div></div>
        }
        return container
    }

    return (
    <div>
        <ButtonGroup>
            <ToggleButton variant="outline-primary" onClick={handleUsersPendingApproval} >Pending</ToggleButton>
            <ToggleButton variant="outline-primary" onClick={handleUsersAll} >All</ToggleButton>
            <ToggleButton variant="outline-primary" onClick={handleClose} >Close</ToggleButton>
        </ButtonGroup>
        {/* <Container>
                <Row className="align-items-center">
                    <Col>
                        Name
                    </Col>
                    <Col>
                        Username
                    </Col>
                    <Col>
                        Status
                    </Col>
                    <Col>
                        Action
                    </Col>
                </Row>
        </Container>
        <ul className="user-list">{userList.map((user) => (
            <User user={user} key={user.username}/>
      ))}</ul> */}

        <Table responsive striped bordered hover>
            <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Username
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Action
                    </th>
                </tr>
            </thead>
            <tbody>
                {userList.map((user) => (
                    <User user={user} key={user.username}/>
                ))}
            </tbody>
        </Table>
    </div>
      )
}

async function getUsersPendingApproval(){
  let data = {
  }
  const response = await axiosInstance.get('/api/users/pendingapproval', data, {
    withCredentials: true,
  })
  return response.data;
}

async function approveUser(user){
    let data = {
        username: user.username,
        action: 'approve'
    }
    const response = await axiosInstance.post('/api/users/approveuser', data, {
        withCredentials: true,
    })
    return response.data;
}

async function getAllUsers(){
    let data = {
    }
    const response = await axiosInstance.get('/api/users/all', data, {
      withCredentials: true,
    })
    return response.data;
}

export default Admin;
import './App.scss';
import './custom.scss'
import React, { useState, useEffect } from 'react';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import { axiosInstance } from './utilities/axios';
import {Container, Spinner} from 'react-bootstrap';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    //Check localstorage for user credentials

    getUser().then(user => {
      if(user){
        setUser(user);
      }
      setLoading(false);
    })
  },[])
  
  const handleSignOut = (user) => {
    localStorage.removeItem('user')
    setUser(null);
  }

  const handleSignIn = (user) => {
    localStorage.setItem('user',JSON.stringify(user))

    getUser().then(user => {
      if(user){
        setUser(user);
      }
    })
  }

  var app;
  if(user){
    app = <AuthenticatedApp user={user} onSignOut={handleSignOut}/>
  }else if(loading){
    app = 
    <div className="login-form">
      <div className="login-logo">Vroom Photos</div>
      <Container fluid className="loading-spinner">
          <Spinner animation="border" role="status" variant="dark">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
      </Container>
    </div>
  }else{
    app = <UnauthenticatedApp onSignIn={handleSignIn}/>
  }

  return (
    <div className="App">
        {app}
    </div>
    
  )
}

export default App;

async function getUser(){
  const user = JSON.parse(localStorage.getItem('user'))

  let data = {user: user}

  const response = await axiosInstance.post('/api/auth/validateUserToken', data, {
    withCredentials: true
  })

  if(!response.data.validated){
      console.log(response.data.message)
      localStorage.removeItem('user')
      return null;
  }else{
    return user;
  }
}
import React from 'react';
import {Nav, Navbar, Container} from 'react-bootstrap'
  
function NavFooter({user, activeKey, onKeySelect}){

  const handleSelect = (eventKey) => {
    onKeySelect(eventKey)
  }
  
  return (
      <Navbar bg="light" variant="secondary" fixed="bottom">    
          <Container>
            <Nav variant="pills" activeKey={activeKey} onSelect={handleSelect} className="nav-footer">
                <Nav.Item>
                  <Nav.Link eventKey="view">View</Nav.Link>
                </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="upload">Upload</Nav.Link>
              </Nav.Item>
            </Nav>
          </Container>       
      </Navbar>
    )
}

export default NavFooter;
import axios from "axios";

//const serverURL = "https://9e82-75-146-134-49.ngrok.io";
const serverURL = "/";

const axiosInstance = axios.create({
  baseURL: serverURL,
  //baseURL: "localhost:8082",
});

export {axiosInstance};





import { openDB } from "idb";

export const idb = {
    vroomphotosDB: openDB('vroomphotos',1,{
        upgrade(db) {
            if(!db.objectStoreNames.contains('states')){
                db.createObjectStore('states', {keyPath: 'name'});
            }  
        }
    })
}
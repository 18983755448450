import styled from "styled-components";
import { Container, Col, Row } from "react-bootstrap";

// const Wrapper = styled.section`
//     padding-top: .5em;
//     padding-bottom: 1em;
// `;

const Label = styled.label`
    color: ${props => props.disabled ? "grey" : "black"};
    font-size: 1em;
    margin: 1em;
    padding: 0.25em 1em;
    border: ${props => props.disabled ? "2px solid Grey" : "2px solid Black"};
    border-radius: 3px;
`;

const Input = styled.input`
    opacity: 0;
    width: 0;
`;

function InputFile({onFileInput, disabled}) {   

    function handleFiles(event){
        const input = event.target;
        const files = input.files;
        onFileInput(files);
    }

    return(
        <Container> 
            <Row className="justify-content-center">
                <Col xs="auto">
                    <Label disabled={disabled} htmlFor="select_photo">Select</Label>
                    <Input disabled={disabled} type="file" onChange={handleFiles} id="select_photo" name="select_photo" alt="uploaded_image" accept="video/*, image/jpg, image/jpeg" multiple/>
                </Col>
                {/* <Col xs="auto">
                    <Label disabled={disabled} htmlFor="take_photo">Camera</Label>
                    <Input disabled={disabled} type="file" onChange={handleFiles} capture="environment" id="take_photo" name="take_photo" alt="uploaded_image" multiple/>
                </Col> */}
            </Row>
        </Container>
     )   
}

export default InputFile;


// //nodejs.org/api for API docs
// //Node.js web server
// var http = require("http"),                           //Import Node.js modules
//     url = require("url"),
//     path = require("path"),
//     fs = require("fs");

// http.createServer(function(request, response) {       //Create server
// var name = url.parse(request.url).pathname;           //Parse URL
// var filename = path.join(process.cwd(), name);        //Create filename
// fs.readFile(filename, "binary", function(err, file) { //Read file
//     if(err) {                                         //Tracking Errors
//         response.writeHead(500, {"Content-Type": "text/plain"});
//         response.write(err + "\n");
//         response.end();
//         return;
//     }
//     response.writeHead(200);                          //Header request response
//     response.write(file, "binary");                   //Sends body response
//     response.end();                                   //Signals to server that
//  });                                                  //header and body sent
// }).listen(3000);                                      //Listening port
// console.log("Server is listening on port 3000.")      //Terminal output

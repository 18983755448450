import React from 'react';
import { axiosInstance } from '../utilities/axios';
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap'
  
function NavHeader({user, onSignOut, onSelectMenu}){

    const handleSettings = (event) => {
        console.log("User settings for " + user.firstname)
    }

    const handleAdmin = (event) => {
      onSelectMenu('admin')
  }

    const handleSignOut = (event) => {
      signOut().then(response => {
        console.log(response)
      })
      onSignOut(user)
    }

    const DropDownItems = ({user}) => {
      let dropDownItems;
      switch(user.role){
        case 'admin':
          dropDownItems = 
          <div>
            <NavDropdown.Item onClick={handleAdmin}>Admin</NavDropdown.Item>
            <NavDropdown.Item onClick={handleSettings}>Settings</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item onClick={handleSignOut}>Sign Out</NavDropdown.Item>
          </div>
        break;
        default:
          dropDownItems = 
          <div>
            <NavDropdown.Item onClick={handleSettings}>Settings</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item onClick={handleSignOut}>Sign Out</NavDropdown.Item>
          </div>
      }
        return dropDownItems;
    }

    return (
        <Navbar bg="light">  
        <Container>
        <Navbar.Brand >Vroom Photos</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav style={{ maxHeight: '100px' }}>
          <NavDropdown title={user.firstname} id="UserControl" variant="light" className="test-class-1">
            <DropDownItems user={user} />
          </NavDropdown>
          </Nav>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      )
}

async function signOut(){
  let data = {
  }
  const response = await axiosInstance.post('/api/auth/logout', data, {
    withCredentials: true,
  })
  return response.data.message;
}

export default NavHeader;
//import { result } from "lodash";
//import React, { useState} from 'react';
//import styled from "styled-components";
import { axiosInstance } from "../utilities/axios";
import { Container, Row, Col, Form } from "react-bootstrap";

// const Wrapper = styled.section`
//     padding-top: 1.5em;
//     padding-bottom: .5em;
// `;

// const Input = styled.input`
//     margin: .5em;
// `;

function JobSearch({user, jobNumberInput, onChange, onJobSearchResult, onJobFound}) {    
    const handleChange = (event) => {
        const jobNumber = event.target.value;
        const query = {
            user: user,
            jobNumber: jobNumber
        }
        fetchJobFolder(query).then(data => {
            if(query.jobNumber === ''){
                //There is nothing in the search box, don't display a result message
                onJobSearchResult('');
            }else{
                if(data.folderName === undefined){
                    onJobSearchResult('No response from NAS server...');
                }else{
                    onJobSearchResult(data.folderName);
                }
            }
            onJobFound(data.foundJobFolder);
        });
        
        onChange(event.target.value)
    }

    return(
        // <Wrapper>
        //     Job Number:
        //     <Input type="text" value={jobNumberInput} onChange={handleChange} placeholder="Enter Job Number"/>
        // </Wrapper>
        <Container>
            <Row className="justify-content-center">
                <Form.Group as={Col} xs="auto" className="mb-3 job-number-input" controlId="formJobNumberInput">
                    <Form.Label column="md" >
                        Job Number:
                    </Form.Label>
                    <Form.Control size="md" type="text" value={jobNumberInput} onChange={handleChange} placeholder="Enter Job Number" autoComplete="off"/>
                </Form.Group>
            </Row>
        </Container>
     )   
}

async function fetchJobFolder(query){
    // const response = await fetch('https://9e82-75-146-134-49.ngrok.io/api/jobs/getjob', {
    // method: 'POST', // or 'PUT'
    // headers: {
    //     'Content-Type': 'application/json',
    //     'credentials' : 'include'
    // },
    // body: JSON.stringify(query),
    // })

    // if (!response.ok){
    //     console.error('Error:', response.status)
    //     return "Fetch error"
    // }

    //return response.json();

    const data = query
    const response = await axiosInstance.post('/api/jobs/getjob',data,{
        withCredentials: true,
    });

    return response.data;
}

export default JobSearch;

import React, {useEffect} from 'react'
import { Button, Container } from "react-bootstrap";
import JobSearch from "./JobSearch";
import { axiosInstance } from "../utilities/axios";

function GridViewer ({
    user, 
    jobNumberInput, setJobNumberInput, 
    jobSearchResult, setJobSearchResult,
    foundJobFolder, setFoundJobFolder,
    loadingPhotos, setLoadingPhotos,
    photoSearchDone, setPhotoSearchDone, 
    loadedPhotos, setLoadedPhotos,
    showPhotoView, setShowPhotoView,
    initialPhotoIndex, setInitialPhotoIndex,
    handleSelectGridItem
}) {

    //const [swiperRef, setSwiperRef] = useState(null)
    const handleLoadPhotos = (event) => {
        const query = {
            user: user,
            jobNumber: jobNumberInput
        }
        setLoadingPhotos(true)
        setPhotoSearchDone(false)
        fetchPhotos(query).then(data => { 
            setPhotoSearchDone(true)
            setLoadingPhotos(false)
            let sortedPhotos = sortPhotosByUser(data)
            setLoadedPhotos(sortedPhotos)
        })
    }

    var loadPhotosButtonText;
    if(!loadingPhotos){
        loadPhotosButtonText = 'Load Photos'
    }else{
        loadPhotosButtonText = 'Loading...'
    }

    useEffect(() => {
        if(!foundJobFolder && photoSearchDone){
            setPhotoSearchDone(false)
        }

        if(!foundJobFolder && loadedPhotos.length !== 0){
            setLoadedPhotos([])
        }
    })

    const GridItem = ({photo, index}) => {
        let gridItem;
        let src = "/api/images?thumbnail=true&filename=" + photo.filename;
        gridItem=
        <div className="photo-flexbox-item">
            <img  id={photo.filename} name={index} className="photo-flexbox-item-image" src={src} onClick={handleSelectGridItem} alt="grid-img"/>
            {/* <canvas width={photo.width} height={photo.height} className="photo-flexbox-item-canvas"></canvas>  */}
            <canvas width={300} height={300} className="photo-flexbox-item-canvas"></canvas> 
        </div>
        return gridItem
    }

    const Grid = ({photos}) => {
        if(photos === undefined){
           return (<div></div>)
        }
        let grid;

        if(photos.length !== 0){
            grid = 
            photos.map((photo,index) => {
                let item;
                if(photo.header === undefined){
                    item = 
                    <GridItem photo={photo} index={index} key={photo.filename}/>
                }else{
                    item = 
                    <div className="grid-header" key={photo.header}>
                        <hr className="hr-header"/>
                        <div  >{photo.header}</div>
                    </div>
                }
                
                return item
            })


        }
        if(photos.length === 0 && !photoSearchDone){
             grid = 
             <div></div>
        }
        if(photos.length === 0 && photoSearchDone){
            grid = 
            <Container>No photos</Container>
       }

        return grid;
    }
    
    return (
        <div>
            <Container className="full-height">
            <JobSearch user={user} jobNumberInput={jobNumberInput} onChange={setJobNumberInput} onJobSearchResult={setJobSearchResult} onJobFound={setFoundJobFolder}/>
            <label>
                {jobSearchResult}
            </label>
            <Container className="load-photos-button">
                <Button id="loadPhotosButton" variant="primary" disabled={!foundJobFolder} onClick={handleLoadPhotos}>{loadPhotosButtonText}</Button>
            </Container>
            
            <div className="photo-flexbox">
                <Grid photos={loadedPhotos}/>
            </div>
            </Container>

        </div>
    )
}

async function fetchPhotos(query){ 
    const data = query
    const response = await axiosInstance.post('/api/images/loadphotos',data,{
        withCredentials: true,
    });

    return response.data;
}

// async function getPhotos(query) {
//     const response = await axiosInstance.get('/api/images', {params: {jobnumber: query.jobNumber}},{
//         withCredentials: true,
//     });
//     return response.data
// }

function sortPhotosByUser(photos){
    photos.sort(function(a,b){
        //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
        var nameA = a.user.username.toUpperCase(); // ignore upper and lowercase
        var nameB = b.user.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        // names must be equal
        return 0;
    })

    
    //Insert headers into photos array
    // for(let i=0;i<photos.length-1;i++){
    //     let header;
    //     if(i===0){
    //         header = {header: photos[0].user.firstname + " " + photos[0].user.lastname}
    //         photos.splice(0,0,header)
    //     }else{
    //         let currentUsername = photos[i].user.username;
    //         let nextUsername = photos[i+1].user.username;
            
    //         if(currentUsername !== nextUsername){
    //             header = {header: photos[i+1].user.firstname + " " + photos[i+1].user.lastname}
    //             photos.splice(i+1,0,header)
    //             i=i+1
    //         }
    //     }
    // }

    return photos;
}

export default GridViewer;